import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import configureStore from '../src/store'

//pdfmake configuration
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs

const container = document.getElementById('root')

const root = ReactDOMClient.createRoot(container)

const store = configureStore()

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)

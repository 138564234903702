/* eslint-disable import/no-anonymous-default-export */
import {
  FETCH_ALL_CENTROSITV_SUCCESS,
  FETCH_CENTROITV_ID,
  CLEAR_CENTROITV,
} from '../actionTypes/centrositv'

const INITIAL_STATE = {
  centrositv: false,
  centroitv: false,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_CENTROSITV_SUCCESS:
      return {
        ...state,
        centrositv: action.payload.data,
      }
    case FETCH_CENTROITV_ID:
      return {
        ...state,
        centroitv: action.payload.data,
      }
    case CLEAR_CENTROITV:
      return {
        ...state,
        centroitv: false,
      }
    default:
      return {
        ...state,
      }
  }
}

/* eslint-disable import/no-anonymous-default-export */
import {
  SAVE_PICKUP_SERVICE_TO_DELIVERY,
  FETCH_ALL_SERVICES_SUCCESS,
  FETCH_SERVICES_STATS_SUCCESS,
  FETCH_SERVICES_PDF_SUCCESS,
  FETCH_SERVICES_CHECK_CUSTOMER_SUCCESS,
  GET_ALL_SERVICES_WITH_PAGINATION_SUCCESS,
  FETCH_SERVICES_CHECK_CAR_SUCCESS,
  SAVE_CUSTOMER_LATLNG,
  UPDATE_SERVICE_SUCCESS,
  CLEAN_SERVICES_TABLE_DATA,
  CLEAR_SERVICE_REDUX,
  FETCH_SERVICE_SUCCESS,
  SAVE_SERVICE_TO_GENERATE_FROM,
  FETCH_SERVICES_AUTOCOMPLETE_PLATE_SUCCESS,
} from '../actionTypes/services'

const INITIAL_STATE = {
  services: false,
  service: false,
  pickupServiceToCopyOnDelivery: false, //false means not fetched
  servicesStatistics: false,
  servicesPdf: false,
  servicesCheckedFromCustomer: false,
  servicesCheckedFromCar: false,
  customerLatLng: false,
  lastUpdatedAt: null,
  totalServicesNumber: false,
  servicesPagination: {},
  serviceToGenerateFrom: false,
  servicesToAutocomplete: false,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SAVE_PICKUP_SERVICE_TO_DELIVERY:
      return {
        ...state,
        pickupServiceToCopyOnDelivery: action.payload.data,
      }
    case FETCH_ALL_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.payload.data,
      }
    case FETCH_SERVICE_SUCCESS:
      return {
        ...state,
        service: action.payload.data,
      }
    case FETCH_SERVICES_STATS_SUCCESS:
      return {
        ...state,
        servicesStatistics: action.payload.data,
      }
    case FETCH_SERVICES_PDF_SUCCESS:
      return {
        ...state,
        servicesPdf: action.payload.data,
      }
    case FETCH_SERVICES_CHECK_CUSTOMER_SUCCESS:
      return {
        ...state,
        servicesCheckedFromCustomer: action.payload.data,
      }
    case GET_ALL_SERVICES_WITH_PAGINATION_SUCCESS: {
      state.servicesPagination[action.payload.page] = action.payload.data.services
      return {
        ...state,
        servicesPagination: {
          ...state.servicesPagination,
          [action.payload.page]: action.payload.data.services,
        },
        totalServicesNumber:
          action.payload.data.total === undefined ? 0 : action.payload.data.total,
      }
    }
    case CLEAN_SERVICES_TABLE_DATA: {
      return {
        ...state,
        servicesPagination: {},
      }
    }
    case FETCH_SERVICES_CHECK_CAR_SUCCESS:
      return {
        ...state,
        servicesCheckedFromCar: action.payload.data,
      }
    case SAVE_CUSTOMER_LATLNG:
      return {
        ...state,
        customerLatLng: action.payload.data,
      }
    case UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        lastUpdatedAt: Date.now(),
      }
    case CLEAR_SERVICE_REDUX:
      return {
        ...state,
        service: false,
      }
    case SAVE_SERVICE_TO_GENERATE_FROM:
      return {
        ...state,
        serviceToGenerateFrom: action.payload.data,
      }
    case FETCH_SERVICES_AUTOCOMPLETE_PLATE_SUCCESS:
      return {
        ...state,
        servicesToAutocomplete: action.payload.data,
      }
    default:
      return {
        ...state,
      }
  }
}

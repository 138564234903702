/* eslint-disable import/no-anonymous-default-export */
import { combineReducers } from 'redux'

import centrositv from './centrositv'
import auth from './auth'
import clientes from './clientes'
import conductores from './conductores'
import customer from './customer'
import delegation from './delegation'
import workshop from './workshop'
import services from './services'
import car from './car'
import notifications from './notifications'
import administradores from './administradores'
import serviceStatuses from './serviceStatuses'
import regiones from './regiones'

export default (state, action) => {
  const reducers = combineReducers({
    centrositv,
    conductores,
    auth,
    clientes,
    customer,
    delegation,
    workshop,
    services,
    car,
    notifications,
    administradores,
    serviceStatuses,
    regiones,
  })

  return reducers(state, action)
}

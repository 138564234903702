import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { checkCurrentUser } from './actions/auth'
import Cookies from 'js-cookie'
import axios from './requesters/axios'
import { tokenName } from './constants'
import PropTypes from 'prop-types'

export default function AuthChecker({ children, history, ...props }) {
  const dispatch = useDispatch()
  const [authChecked, setAuthChecked] = useState(null)
  const authenticated = useSelector((s) => s.auth.authenticated)

  useEffect(() => {
    const cookieToken = Cookies.get(tokenName)
    axios.defaults.headers.Authorization = `Bearer ${cookieToken}`
    dispatch(
      checkCurrentUser(
        () => {
          setAuthChecked(true)
        },
        () => {
          //IF FAILS RETURN TO LOGIN
          setAuthChecked(false)
        },
      ),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  AuthChecker.propTypes = {
    children: PropTypes.any.isRequired,
    history: PropTypes.any.isRequired,
  }

  return authChecked === null ? (
    <div>Cargando...</div>
  ) : authenticated ? (
    children
  ) : (
    <Navigate to="/login" />
  )
}

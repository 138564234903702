/* eslint-disable import/no-anonymous-default-export */
import { GET_ALL_SERVICE_STATUSES_SUCCESS } from '../actionTypes/serviceStatuses'

const INITIAL_STATE = {
  serviceStatuses: false,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALL_SERVICE_STATUSES_SUCCESS:
      return {
        ...state,
        serviceStatuses: action.payload.data,
      }
    default:
      return {
        ...state,
      }
  }
}

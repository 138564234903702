/* eslint-disable import/no-anonymous-default-export */
import {
  FETCH_ALL_DELEGATIONS_SUCCESS,
  FETCH_DELEGATION_ID,
  FETCH_DELEGATION_ADMINS,
  FETCH_DELEGATION_DRIVERS,
  FETCH_DELEGATION_CALL_CENTERS,
  FETCH_DELEGATION_WORKSHOPS,
  FETCH_DELEGATION_AVAILABLE_PICKUP_RANGES,
  FETCH_DELETED_DELEGATION_ADMINS,
  FETCH_DELETED_DELEGATION_CALL_CENTERS,
  FETCH_DELETED_DELEGATION_DRIVERS,
  FETCH_DELETED_DELEGATION_WORKSHOPS,
} from '../actionTypes/delegations'

const INITIAL_STATE = {
  delegations: false, //false means not fetched
  delegation: false,
  delegationDrivers: false,
  delegationAdmins: false,
  delegationCallCenters: false,
  delegationWorkshops: false,
  availablePickupRanges: false,
  deletedDelegationDrivers: false,
  deletedDelegationCallCenters: false,
  deletedDelegationAdmins: false,
  deletedDelegationWorkshops: false,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_DELEGATIONS_SUCCESS:
      return {
        ...state,
        delegations: action.payload.data,
      }
    case FETCH_DELEGATION_ID:
      return {
        ...state,
        delegation: action.payload.data,
      }
    case FETCH_DELEGATION_ADMINS:
      return {
        ...state,
        delegationAdmins: action.payload.data,
      }
    case FETCH_DELEGATION_DRIVERS:
      return {
        ...state,
        delegationDrivers: action.payload.data,
      }
    case FETCH_DELEGATION_CALL_CENTERS:
      return {
        ...state,
        delegationCallCenters: action.payload.data,
      }
    case FETCH_DELEGATION_WORKSHOPS:
      return {
        ...state,
        delegationWorkshops: action.payload.data,
      }
    case FETCH_DELEGATION_AVAILABLE_PICKUP_RANGES:
      return {
        ...state,
        availablePickupRanges: action.payload.data,
      }
    case FETCH_DELETED_DELEGATION_ADMINS:
      return {
        ...state,
        deletedDelegationAdmins: action.payload.data,
      }
    case FETCH_DELETED_DELEGATION_CALL_CENTERS:
      return {
        ...state,
        deletedDelegationCallCenters: action.payload.data,
      }
    case FETCH_DELETED_DELEGATION_DRIVERS:
      return {
        ...state,
        deletedDelegationDrivers: action.payload.data,
      }
    case FETCH_DELETED_DELEGATION_WORKSHOPS:
      return {
        ...state,
        deletedDelegationWorkshops: action.payload.data,
      }
    default:
      return {
        ...state,
      }
  }
}

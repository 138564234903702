/* eslint-disable import/no-anonymous-default-export */
import {
  FETCH_ALL_WORKSHOPS_SUCCESS,
  FETCH_WORKSHOPS_CLIENTID,
  FETCH_WORKSHOP_USERS,
  FETCH_WORKSHOP_ISSUES,
  FETCH_DELETED_WORKSHOP_USERS,
  FETCH_WORKSHOP_DETAILS,
  FETCH_WORKSHOP_CREATE,
} from '../actionTypes/workshops'

const INITIAL_STATE = {
  workshops: false, //false means not fetched
  workshop: false,
  workshopUsers: false,
  workshopServices: false,
  deletedWorkshopUsers: false,
  workshopdetails: false,
  workshopcreate: false,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_WORKSHOPS_SUCCESS:
      return {
        ...state,
        workshops: action.payload.data,
      }
    case FETCH_WORKSHOPS_CLIENTID:
      return {
        ...state,
        workshops: action.payload.data,
      }

    case FETCH_WORKSHOP_USERS:
      return {
        ...state,
        workshopUsers: action.payload.data,
      }
    case FETCH_WORKSHOP_ISSUES:
      return {
        ...state,
        workshopServices: action.payload.data,
      }
    case FETCH_DELETED_WORKSHOP_USERS:
      return {
        ...state,
        deletedWorkshopUsers: action.payload.data,
      }
    case FETCH_WORKSHOP_DETAILS:
      return {
        ...state,
        workshopdetails: action.payload.data,
      }
    case FETCH_WORKSHOP_CREATE:
      return {
        ...state,
        workshopcreate: action.payload.data,
      }

    default:
      return {
        ...state,
      }
  }
}

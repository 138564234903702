import {
  GET_CURRENTUSER_NOTIFICATIONS_SUCCESS,
  GET_CURRENTUSER_UNSEEN_NOTIFICATIONS_SUCCESS,
  GET_CURRENTUSER_UNSEEN_NOTIFICATIONS_COUNT_SUCCESS,
  GET_CURRENTUSER_SERVICE_UNSEEN_NOTIFICATIONS_SUCCESS,
} from '../actionTypes/notifications'

const INITIAL_STATE = {
  notifications: false,
  unseenNotifications: false,
  unseenNotificationsCount: false,
  unseenServiceNotifications: false,
}

export default function Notifications(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CURRENTUSER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload.data,
      }
    case GET_CURRENTUSER_UNSEEN_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        unseenNotifications: action.payload.data,
      }
    case GET_CURRENTUSER_UNSEEN_NOTIFICATIONS_COUNT_SUCCESS:
      return {
        ...state,
        unseenNotificationsCount: action.payload.data.total,
      }
    case GET_CURRENTUSER_SERVICE_UNSEEN_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        unseenServiceNotifications: action.payload.data,
      }
    default:
      return {
        ...state,
      }
  }
}

import axios from '../requesters/axios'
import { tokenName, baseURL } from '../constants/index'
import { AUTH_USER_LOGIN, UNAUTH_USER } from '../actionTypes/auth'
import Cookies from 'js-cookie'
import { apiErrorHandler } from './errors'

export const loginUser = (payload, done) => (dispatch) => {
  axios
    .post(`${baseURL}/authenticate`, payload, {
      withCredentials: false,
    })
    .then((response) => {
      axios.defaults.headers.Authorization = `Bearer ${response.data.token}`
      Cookies.set(tokenName, response.data.token)
      dispatch({
        type: AUTH_USER_LOGIN,
        payload: {
          currentUser: response.data.currentUser,
        },
      })
      done(response)
    })
    .catch((error) => {
      console.log(error)
      apiErrorHandler(error)
      //done(null, error)
    })
}

//BY THE CURRENT AXIOS TOKEN (MUST BE SETTED EVERY TIME THE APP IS LOADED, GETTING IT FROM THE COOKIE TOKEN) RETURNS THE CURRENT USER AND SAVES IT IN REDUX
export const checkCurrentUser =
  (done = () => {}, errorCallback = () => {}) =>
  (dispatch) => {
    axios
      .get(`${baseURL}/me`, {
        withCredentials: true,
      })
      .then((response) => {
        dispatch({
          type: AUTH_USER_LOGIN,
          payload: {
            currentUser: response.data,
          },
        })
        done(response)
      })
      .catch((error) => {
        errorCallback(error)
        //done(null, error)
      })
  }

export function logOutUser(done) {
  return (dispatch) => {
    Cookies.remove(tokenName)
    axios.defaults.headers.Authorization = ''
    dispatch({
      type: UNAUTH_USER,
    })
    done()
  }
}

export const changeCurrentUserPassword =
  (body, done = () => {}) =>
  () => {
    axios
      .put(`/currentUser/password`, body)
      .then((response) => {
        done(response.data)
      })
      .catch((error) => {
        console.log(error)
        //apiErrorHandler(error)
      })
  }

import React, { Component, Suspense, useLayoutEffect } from 'react'
import { HashRouter, Route, Routes, useLocation } from 'react-router-dom'
import './scss/style.scss'
import 'react-datetime/css/react-datetime.css'
import AuthChecker from './AuthChecker'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

//Scroll to top in each transition with navigate

const Wrapper = ({ children }) => {
  const location = useLocation()
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])
  return children
}

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Wrapper>
            <Routes>
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route exact path="/register" name="Register Page" element={<Register />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />

              <Route
                path="*"
                element={
                  <AuthChecker>
                    <DefaultLayout />
                  </AuthChecker>
                }
              />
            </Routes>
          </Wrapper>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App

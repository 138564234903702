export const FETCH_ALL_DELEGATIONS_SUCCESS = 'FETCH_ALL_DELEGATIONS_SUCCESS'
export const FETCH_DELEGATION_ID = 'FETCH_DELEGATION_ID'
export const FETCH_DELEGATION_DRIVERS = 'FETCH_DELEGATION_DRIVERS'
export const FETCH_DELEGATION_ADMINS = 'FETCH_DELEGATION_ADMINS'
export const FETCH_DELEGATION_CALL_CENTERS = 'FETCH_DELEGATION_CALL_CENTERS'
export const FETCH_DELEGATION_WORKSHOPS = 'FETCH_DELEGATION_WORKSHOPS'
export const FETCH_DELEGATION_AVAILABLE_PICKUP_RANGES = 'FETCH_DELEGATION_AVAILABLE_PICKUP_RANGES'
export const FETCH_DELETED_DELEGATION_DRIVERS = 'FETCH_DELETED_DELEGATION_DRIVERS'
export const FETCH_DELETED_DELEGATION_ADMINS = 'FETCH_DELETED_DELEGATION_ADMINS'
export const FETCH_DELETED_DELEGATION_CALL_CENTERS = 'FETCH_DELETED_DELEGATION_CALL_CENTERS'
export const FETCH_DELETED_DELEGATION_WORKSHOPS = 'FETCH_DELETED_DELEGATION_WORKSHOPS'

export const SAVE_PICKUP_SERVICE_TO_DELIVERY = 'SAVE_PICKUP_SERVICE_TO_DELIVERY'
export const FETCH_ALL_SERVICES_SUCCESS = 'FETCH_ALL_SERVICES_SUCCESS'
export const FETCH_SERVICES_STATS_SUCCESS = 'FETCH_SERVICES_STATS_SUCCESS'
export const FETCH_SERVICES_PDF_SUCCESS = 'FETCH_SERVICES_PDF_SUCCESS'
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS'
export const FETCH_SERVICES_CHECK_CUSTOMER_SUCCESS = 'FETCH_SERVICES_CHECK_CUSTOMER_SUCCESS'
export const FETCH_SERVICES_CHECK_CAR_SUCCESS = 'FETCH_SERVICES_CHECK_CAR_SUCCESS'
export const SAVE_CUSTOMER_LATLNG = 'SAVE_CUSTOMER_LATLNG'
export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS'
export const GET_ALL_SERVICES_WITH_PAGINATION_SUCCESS = 'GET_ALL_SERVICES_WITH_PAGINATION_SUCCESS'
export const CLEAN_SERVICES_TABLE_DATA = 'CLEAN_SERVICES_TABLE_DATA'
export const CLEAR_SERVICE_REDUX = 'CLEAR_SERVICE_REDUX'
export const FETCH_SERVICE_SUCCESS = 'FETCH_SERVICE_SUCCESS'
export const SAVE_SERVICE_TO_GENERATE_FROM = 'SAVE_SERVICE_TO_GENERATE_FROM'
export const FETCH_SERVICES_AUTOCOMPLETE_PLATE_SUCCESS = 'FETCH_SERVICES_AUTOCOMPLETE_PLATE_SUCCESS'

/* eslint-disable import/no-anonymous-default-export */
import { FETCH_CLIENTE_ID, FETCH_ALL_CLIENTES_SUCCESS } from 'src/actionTypes/clientes'
const INITIAL_STATE = {
  clientes: false,
  cliente: false,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_CLIENTES_SUCCESS:
      return {
        ...state,
        clientes: action.payload.data,
      }
    case FETCH_CLIENTE_ID:
      return {
        ...state,
        cliente: action.payload.data,
      }
    default:
      return {
        ...state,
      }
  }
}

import { legacy_createStore as createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import rootReducer from './reducers'

const middlewares = [thunk]

const enhancers = []

export default function configureStore(initialState) {
  // if (process.env.NODE_ENV === 'develop') {
  //   const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  //   if (typeof devToolsExtension === 'function') {
  //     enhancers.push(devToolsExtension())
  //   }
  // }

  const composeEnhancers = composeWithDevTools({
    // options like actionSanitizer, stateSanitizer
  })

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares), ...enhancers),
  )

  return store
}

/* eslint-disable import/no-anonymous-default-export */
import { FETCH_ALL_REGIONES_SUCCESS } from '../actionTypes/regiones'

const INITIAL_STATE = {
  regiones: false, //false means not fetched
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_REGIONES_SUCCESS:
      return {
        ...state,
        regiones: action.payload.data,
      }
    default:
      return {
        ...state,
      }
  }
}

/* eslint-disable import/no-anonymous-default-export */
import {
  FETCH_ADMINISTRADOR_ID,
  FETCH_ALL_ADMINISTRADORES_SUCCESS,
  CLEAR_ADMINISTRADOR_REDUX,
  ERASE_ADMINISTRADOR_ID,
} from 'src/actionTypes/administradores'

const INITIAL_STATE = {
  administradores: false,
  administrador: false,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_ADMINISTRADORES_SUCCESS:
      return {
        ...state,
        administradores: action.payload.data,
      }
    case FETCH_ADMINISTRADOR_ID:
      return {
        ...state,
        administrador: action.payload.data,
      }

    case CLEAR_ADMINISTRADOR_REDUX:
      return {
        ...state,
        administrador: false,
      }
    case ERASE_ADMINISTRADOR_ID:
      return {
        ...state,
        administradores: state.administradores.filter((item) => item.ID !== action.payload.id),
      }
    default:
      return {
        ...state,
      }
  }
}

/* eslint-disable import/no-anonymous-default-export */
import { FETCH_CARS_DONE } from '../actionTypes/cars'

const INITIAL_STATE = {
  cars: false, //false means not fetched
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_CARS_DONE:
      return {
        ...state,
        cars: action.payload.data,
      }
    default:
      return {
        ...state,
      }
  }
}
